import React from "react";
import "./home.css";
import Navbar from "../Navbar/Navbar";

const Home = () => {
  return (
    <>
      <Navbar />
      <div>
        <div className="inf-section">
          <img
            src="/images/hirings.jpeg"
            alt="Loading..."
            className="inf-image"
          />
          <div className="inf-content">
            <h2 className="inf-heading">
              Cease the Quest – Join Our Team Today
            </h2>
            <ul className="inf-points">
              <li>
                Discover exceptional candidates for temporary or permanent
                positions with ease
              </li>
              <li>Proficient Talent Alignment with Job Roles</li>
              <li>
                Effortlessly match talent across all levels, no complications
              </li>
            </ul>
            {/* <button className="beautiful-button" style={{ marginTop: "10px" }}>
            Explore Your Next Recruitment
          </button> */}
          </div>
        </div>
        <div className="welcome-container">
          <header className="banner">
            <h1>Fostering Connections, Unleashing Potential.</h1>
            <p>
              Welcome to KACEE Technologies - Your premier staffing and talent
              acquisition ally.
            </p>
          </header>

          <section className="short-intro">
            <p>
              At KACEE technologies, we bridge the gap between talent and
              opportunity. Our dedicated team leverages deep industry knowledge
              and a vast network to connect top-tier companies with the
              professionals who will drive their success.
            </p>
          </section>
        </div>
        <div className="home-page-content" id="section1">
          <section className="success-stories">
            <h2>Real Success, Real People</h2>
            <p>
              Dive into inspiring stories of how we've connected talented
              individuals with their dream jobs and helped companies find their
              perfect matches. Discover firsthand accounts from satisfied
              clients and candidates who have experienced the difference with
              KACEE Technologies
            </p>
          </section>

          <section className="featured-jobs">
            <h2>Find Your Next Opportunity</h2>
            <p>
              {" "}
              Explore some of our top job listings available right now. Whether
              you're looking for a change, a challenge, or a chance to advance
              your career, your next role could be waiting for you.
            </p>
          </section>

          <section className="meet-our-team">
            <h2>Meet the Experts Behind Your Success</h2>
            <p>
              Our team of experienced and dedicated recruiters are the
              cornerstone of our success. Learn more about the passionate
              professionals who will be working tirelessly to connect you with
              your next opportunity or talent.
            </p>
          </section>

          <section className="how-we-work">
            <h2>Our Approach to Your Success</h2>
            <p>
              Discover our unique, personalized approach to staffing and
              recruitment. From understanding your specific needs to delivering
              exceptional matches, learn how our process sets us apart in
              finding the right fit every time.
            </p>
          </section>
        </div>
        <div className="mvv-container">
          <section className="vision">
            <h2>Our Purpose</h2>
            <p>
              "Our aim is to deliver inventive staffing solutions that empower
              both individuals and organizations to achieve their utmost
              potential."
            </p>
          </section>

          <section className="vision">
            <h2>Our Aspiration</h2>
            <p>
              "We aspire to become the foremost and reliable recruitment ally
              recognized for our unwavering dedication to excellence, integrity,
              and personalized attention."
            </p>
          </section>

          <section className="vision">
            <h2>Our Core Principles</h2>
            <ul className="ul">
              <li>
                <strong>Integrity:</strong> Our operations are guided by
                integrity, honesty, and the highest ethical standards.
              </li>
              <li>
                <strong>Excellence:</strong> We are committed to achieving
                excellence in all aspects of our work, delivering superior
                results consistently.
              </li>
              <li>
                <strong>Collaboration:</strong> We value collaboration and
                teamwork, partnering closely with clients and candidates to
                fulfill their objectives.
              </li>
            </ul>
          </section>
        </div>
        <div className="partner-container">
          <section className="services-offered">
            <h2>Explore Our Services</h2>
            <ul>
              <li>Temporary and Contract Staffing Solutions</li>
              <li>Permanent Recruitment Services</li>
              <li>Talent Management Consulting</li>
            </ul>
          </section>
          <section className="why-partner">
            <h2>Benefits of Partnering with Us</h2>
            <p>
              "Our holistic staffing approach guarantees not just candidates,
              but the ideal talent who resonate with your company's values and
              goals. Whether it's temporary placements or permanent hires, we
              provide customized services to suit your specific business
              requirements."
            </p>
          </section>
        </div>
        <div className="contact-blog-container">
          <section className="contact">
            <h2>Ready to unleash your potential? Get in touch with us now.</h2>
            <p>
              Our skilled team is prepared to assist you with your staffing and
              professional goals.
            </p>

            <div className="contact-details">
              <p>Kacee Technologies services, Inc</p>
              <p>Phone: +1 (530) 712-3815</p>
              <p>Email: hr@kaceetek.com</p>
              <p>Address: 17 Dillon Dr, Dix Hills, NY 11746</p>
            </div>
          </section>
        </div>
        <div className="header-container">
          <h1 className="main-heading">Elevate Your Career Path</h1>
          <p className="sub-heading">
            Discover Opportunities that Match Your Ambitions and Drive Your
            Professional Growth.
          </p>
          {/* <button className="beautiful-button">Explore Opportunities</button> */}
        </div>
      </div>
    </>
  );
};

export default Home;
